.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center; /* This ensures vertical centering of items */
}

.navbar-background {
    background-color: #ce62ce;
    box-shadow: 0em -5px 20px 8px rgba(128, 0, 128, 0.683);
}

.mobile-header-logo {
    max-height: 4em;
}

.social-container {
    display: block; /* This line is usually unnecessary but can help enforce the desired behavior */
    color: #ddd; /* If you want to apply the same color to both, you can do it here */
}

.social-link {
    padding: 0.3em;
    border: 2px solid #ddd;
    border-radius: 10px;
}