@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Oswald', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media (min-width: 768px) {
  body {
    /* The image used */
    background-image: url("./img/Logo_transparent.png");

    /* Full height */
    height: 100vh;

    /* Center and scale the image nicely */
    background-position: 50% 30%;
    background-repeat: no-repeat;
    background-size: 20%;
    background-color: #ffbbff;
  }
}
